@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --g-c-c1: #AFC9BB;
    --g-c-c2: #637089;
    --g-c-primary: #f4f4f4; /* #FFF4EA; */
    --g-c-secondary: #3D4543;
    --g-c-text: #375542;
    --g-c-accent: #FFC844;
    --g-c-83beb66: #2D2A26;
    --g-c-59443fa: #6AAAE4;
    --g-c-36a0c79: #71A850;
    --g-c-another: #F4F4E4; /* 244,244,228 */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .modern-gradient { */
/*   background-image: */
/*     linear-gradient( */
/*       to bottom left in oklab, */
/*       oklch(76% 0.15 153) 0%, oklch(96% 0.13 95) 100% */
/*     ) */
/*   ; */
/* } */
/* .modern-gradient { */
/*   background-image: */
/*     linear-gradient( */
/*       1deg in oklab, */
/*       oklch(90% 0.07 89) 0%, oklch(83% 0.14 82) 100% */
/*     ) */
/*   ; */
/* } */
